/*
 * @Author: zhang yusen.zhang@mjk24.com
 * @Date: 2022-04-21 09:15:26
 * @LastEditors: zhang yusen.zhang@mjk24.com
 * @LastEditTime: 2022-09-26 14:49:30
 */
import React from "react";
import classnames from "classnames";
import { Link, useIntl } from "gatsby-plugin-intl";
import { cx, css } from "@emotion/css";

const Footer = (props) => {
  const { light = false, children, className, textColor } = props;
  const { formatMessage } = useIntl();

  return (
    <footer className={classnames("w-full", className)}>
      {children}
      <div
        className={cx(
          "w-full py-5 text-xs text-center",
          {
            [light ? "bg-transparent" : "bg-gray-e2"]: true,
            [light ? "color-blue-text" : "color-gray-5d"]: true,
          },
          textColor &&
            css`
              color: ${textColor};
            `
        )}
      >
        <span className="inline-block sm:mr-2 mt-1 sm:mt-0">
          ©2022{" "}
          {formatMessage({
            id: "global.mjk",
          })}
        </span>
        <span className="hidden sm:inline-block mr-2 mt-1 sm:mt-0">
          <a target="_blank" href="http://beian.miit.gov.cn" rel="noreferrer">
            苏ICP备18027894号-9
          </a>
        </span>
        <span className="hidden sm:inline-block mt-1 sm:mt-0">
          <img
            className="inline-block mr-1"
            src="https://www.beian.gov.cn/portal/download?token=e12e8ef7-da3e-4e67-a97e-3d131f1b3961"
          />
          苏公网安备 32059002004158号
        </span>
        <span className="mx-2">|</span>
        <span className="inline-block">
          <Link
            to="/privacy"
            className={cx(
              light ? "color-blue-text" : "color-blue-5c8",
              textColor &&
                css`
                  color: ${textColor};
                `
            )}
          >
            {formatMessage({
              id: "global.privacy",
            })}
          </Link>
        </span>
      </div>
    </footer>
  );
};

export default Footer;

import React, { Fragment, useEffect } from "react";
import { useIntl, Link, changeLocale, navigate } from "gatsby-plugin-intl";
import { Link as ScrollLink, scroller } from "react-scroll";
import classnames from "classnames";
import logo from "@/static/logo.png";
import { Popover, Transition, Menu, Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { FreeTrial } from "../";
import { Helmet } from "react-helmet";
import { cx, css } from "@emotion/css";

const menus = ["products", "cases", "news", "about", "contact"];
const products = [
  "efms",
  "industrial",
  "eipm",
  // "ecms"
];
const locales = ["zh", "en"];

const Header = (props) => {
  const { light = false, transparent = false, location = {}, link = false, className } = props;
  const { locale, formatMessage } = useIntl();
  const { pathname, search } = location;

  const homePaths = [`/${locale}`, `/${locale}/`, "/"];
  const title = React.useMemo(() => {
    if (pathname) {
      const paths = pathname.split("/").filter((v) => !!v && !["zh", "en"].includes(v));

      if (paths.length > 0) {
        return "menu." + paths.join(".");
      }
    }
  }, [pathname]);

  const isNewsPage = React.useMemo(() => {
    if (!title) return false;
    if (!title.includes("news.detail")) return false;
    const titleArr = title.split(".");
    return decodeURI(titleArr[titleArr.length-1]);
  }, [title]);

  // ! 适配返回首页时回到指定位置
  useEffect(() => {
    if (link && search) {
      let searchObj = {};
      let arr = search.slice(1).split("&");
      arr &&
        arr.forEach((item) => {
          let newArr = item?.split("=");
          searchObj[newArr[0]] = newArr[1];
        });
      if (searchObj["anchor"]) {
        scroller.scrollTo(searchObj["anchor"], {
          containerId: "gatsby-focus-wrapper",
        });
      }
    }
  }, [link]);

  useEffect(() => {
    var _hmt = _hmt || [];
    // 百度统计 ?
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?a69eef7a1dd07b83c1fbc27d302da77a";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
    // 百度客服
    var kf = document.createElement("script");
    kf.src = "https://hm.baidu.com/hm.js?7e3561ebc60fd314236a96df5a233f7f";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(kf, s);
  }, []);

  return (
    <header
      className={classnames(
        "w-full",
        {
          "bg-white": !light,
        },
        className
      )}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {title
            ? formatMessage({
                id: isNewsPage ? isNewsPage : title,
              }) + " - "
            : ""}
          {formatMessage({
            id: "global.mjk",
          })}
        </title>
        <meta
          name="description"
          content="苏州曼捷科智能科技有限公司成立于2016年，致力于工业物联网，企业信息化管理软件和互联网平台的研发，咨询和应用。eFMS设施管理、工业4.0-生产管理电子化和eIPM设施项目管理系统应用于领域涵盖汽车零部件，生物制药，化工，建筑设计院，工程全过程管理，电子半导体等国内外著名企业。"
        />
        <meta
          name="keywords"
          content="曼捷科,苏州曼捷科,曼捷科智能科技,eFMS,eFMS设施管理,eFMS-精准电子化设施管理系统,厂务设施管理,厂务管理平台,厂务标准化,访客管理,访客预约,访客登记,智慧巡检,保安巡检,隐患排查,设备维护,设备设施管理,设施设备点检,施工管理,承包商管理、施工预约、施工人员管理,餐费管理,餐费结算,工位管理,工单报修,热线报修,能源管理,智能抄表,安全预警,能源抄表,班车管理,工作流,厂务流程审批管理,文档管理,卡证管理,员工卡管理,餐卡管理,证件卡管理,员工培训,物流叫号,仓库叫号,会议预定,备件管理,仓库管理,硬件管理,办公用品管理,项目管理,全景图,图纸交互,钥匙管理,停车管理,智慧停车,门禁管理,安全检查,消安中断,隐患排查,物品携出,资产携出,罚单管理,入厂培训,访客培训,施工安全培训,装卸货培训"
        />
        <link rel="canonical" href="https://www.mjk24.com/" />
      </Helmet>
      <Popover className="max-w-7xl h-20 mx-auto flex flex-none justify-center items-center px-2 2xl:px-0">
        {({ open }) => (
          <Fragment>
            <Link to="/">
              <img src={logo} alt="MJK" className="w-24" />
            </Link>
            <div className="flex-auto"></div>
            <Popover.Group as="nav" className="hidden mt-2 md:flex md:items-center">
              {link ? (
                <ScrollLink
                  containerId="gatsby-focus-wrapper"
                  activeClass="color-blue"
                  className={classnames("mr-6 lg:mr-10 font-normal text-sm transition text-white cursor-pointer")}
                  to="home"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  {formatMessage({
                    id: "menu.home",
                  })}
                </ScrollLink>
              ) : (
                <Link
                  to="/"
                  className={classnames("mr-6 lg:mr-10 font-normal text-sm", {
                    [homePaths.includes(pathname) ? "color-blue" : light ? "color-blue-text" : "color-black"]: true,
                  })}
                >
                  {formatMessage({
                    id: "menu.home",
                  })}
                </Link>
              )}

              {menus.map((menu) => {
                if (menu.includes("products")) {
                  return (
                    <Popover className="relative" key={menu}>
                      {({ open }) => (
                        <Fragment>
                          <Popover.Button
                            className={cx(
                              "mr-6 lg:mr-10 font-normal text-sm inline-flex items-center focus:outline-none",
                              {
                                [pathname.includes(menu)
                                  ? "color-blue"
                                  : light
                                  ? transparent
                                    ? "text-white"
                                    : "color-blue-text"
                                  : "color-black"]: true,
                              }
                            )}
                          >
                            <div className="relative">
                              <span>
                                {formatMessage({
                                  id: `menu.${menu}`,
                                })}
                              </span>
                              <span
                                className={classnames(
                                  "absolute w-7 rounded -bottom-2 left-1/2 transform -translate-x-1/2 opacity-0",
                                  {
                                    [pathname.includes(menu) ? "opacity-100" : "opacity-0"]: true,
                                  }
                                )}
                                style={{
                                  height: 3,
                                  backgroundColor: "#1890FF",
                                }}
                              ></span>
                            </div>
                            <ChevronDownIcon className="h-4 w-4 -mr-2" aria-hidden="true" />
                          </Popover.Button>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel
                              static
                              className={classnames(
                                "absolute z-10 bg-white mt-3 rounded shadow-md overflow-hidden text-center",
                                locale.includes("en") ? "w-60" : "w-44"
                              )}
                            >
                              {products.map((product) =>
                                link ? (
                                  <ScrollLink
                                    containerId="gatsby-focus-wrapper"
                                    activeClass="color-blue"
                                    className={classnames(
                                      "block py-2 hover:bg-gray-50 text-sm font-normal transition cursor-pointer"
                                    )}
                                    key={product}
                                    to={product}
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                  >
                                    {formatMessage({
                                      id: `menu.${menu}.${product}`,
                                    })}
                                  </ScrollLink>
                                ) : (
                                  <a
                                    className={classnames(
                                      "block py-2 hover:bg-gray-50 text-sm font-normal cursor-pointer",
                                      {
                                        [pathname.includes(product)
                                          ? "color-blue"
                                          : light
                                          ? "color-blue-text"
                                          : "color-black"]: true,
                                      }
                                    )}
                                    key={product}
                                    // to={`/${menu}/${product}`}
                                    onClick={() => {
                                      navigate(`/?anchor=${product}`);
                                    }}
                                  >
                                    {formatMessage({
                                      id: `menu.${menu}.${product}`,
                                    })}
                                  </a>
                                )
                              )}
                            </Popover.Panel>
                          </Transition>
                        </Fragment>
                      )}
                    </Popover>
                  );
                }
                return link ? (
                  <ScrollLink
                    containerId="gatsby-focus-wrapper"
                    activeClass="color-blue"
                    className={classnames(
                      "relative mr-6 lg:mr-10 font-normal text-sm transition text-white cursor-pointer"
                    )}
                    key={menu}
                    to={menu}
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    {formatMessage({
                      id: `menu.${menu}`,
                    })}
                  </ScrollLink>
                ) : (
                  <a
                    key={menu}
                    // to={`/${menu}`}
                    className={classnames("relative mr-6 lg:mr-10 font-normal text-sm cursor-pointer", {
                      [pathname.includes(menu)
                        ? "color-blue"
                        : light
                        ? transparent
                          ? "text-white"
                          : "color-blue-text"
                        : "color-black"]: true,
                    })}
                    onClick={() => {
                      console.log(menu);
                      navigate(`/?anchor=${menu}`);
                    }}
                  >
                    <span>
                      {formatMessage({
                        id: `menu.${menu}`,
                      })}
                    </span>
                    <span
                      className={classnames(
                        "absolute w-7 rounded -bottom-2 left-1/2 transform -translate-x-1/2 opacity-0",
                        {
                          [pathname.includes(menu) ? "opacity-100" : "opacity-0"]: true,
                        }
                      )}
                      style={{
                        height: 3,
                        backgroundColor: "#1890FF",
                      }}
                    ></span>
                  </a>
                );
              })}
              <Menu as="div" className="relative inline-block text-left mr-3">
                {({ open: localeOpen }) => (
                  <Fragment>
                    <div>
                      <Menu.Button
                        className={cx(
                          "inline-flex justify-center items-center w-full rounded border border-gray-200 shadow-sm px-2 py-0.5 text-sm",
                          transparent ? "text-white hover:border-gray-100" : "bg-white hover:bg-gray-50 text-gray-400"
                        )}
                      >
                        <span>
                          {formatMessage({
                            id: `global.locale.${locale}.short`,
                          })}
                        </span>
                        <ChevronDownIcon className="-mr-1 ml-4 h-4 w-4" aria-hidden="true" />
                      </Menu.Button>
                    </div>
                    <Transition
                      show={localeOpen}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                      >
                        <div className="py-1">
                          {locales.map((item) => (
                            <Menu.Item key={item}>
                              {({ active }) => (
                                <button
                                  className={classnames("block px-4 py-2 text-sm w-full", {
                                    [active ? "bg-gray-100 text-gray-900" : "text-gray-700"]: true,
                                  })}
                                  onClick={() => {
                                    changeLocale(item);
                                  }}
                                >
                                  {formatMessage({
                                    id: `global.locale.${item}`,
                                  })}
                                </button>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Fragment>
                )}
              </Menu>
              {/* <FreeTrial
                className={classnames("px-3 py-1 text-white rounded font-normal text-sm", {
                  [light ? "bg-blue" : "bg-orange"]: true,
                  [light ? "hover:bg-blue-500" : "hover:bg-yellow-500"]: true,
                })}
              /> */}
            </Popover.Group>
            <div className="md:hidden">
              <Popover.Button
                className={cx(
                  "rounded-md p-2 inline-flex items-center justify-center focus:outline-none",
                  transparent
                    ? "border border-gray-100 text-white hover:text-gray-300 hover:bg-gray-800"
                    : "bg-white text-gray-400 hover:text-gray-500 hover:bg-gray-100"
                )}
              >
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50"
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                  <div className="pt-5 pb-6 px-5">
                    <div className="flex items-center justify-between">
                      <div>
                        <Link to="/" className="focus:outline-none">
                          <img src={logo} alt="MJK" className="h-7 w-auto" />
                        </Link>
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
                          <span className="sr-only">Close menu</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="mt-6">
                      <nav className="grid gap-y-8">
                        <Link
                          to="/"
                          className={classnames("-m-3 p-3 flex items-center rounded-md hover:bg-gray-50", {
                            "bg-gray-50": homePaths.includes(pathname),
                          })}
                        >
                          <span
                            className={classnames("ml-3 text-sm font-normal", {
                              [homePaths.includes(pathname) ? "color-blue" : "text-gray-900"]: true,
                            })}
                          >
                            {formatMessage({
                              id: "menu.home",
                            })}
                          </span>
                        </Link>
                        {menus.map((menu) => {
                          if (menu.includes("products")) {
                            return (
                              <Disclosure key={menu} defaultOpen={true}>
                                {({ open }) => (
                                  <Fragment>
                                    <Disclosure.Button className={classnames("-m-3 p-3 flex items-center rounded-md")}>
                                      <span
                                        className={classnames("ml-3 text-sm font-normal flex-1 text-left", {
                                          [pathname.includes(menu) ? "color-blue" : "text-gray-900"]: true,
                                        })}
                                      >
                                        {formatMessage({
                                          id: `menu.${menu}`,
                                        })}
                                      </span>
                                      <ChevronRightIcon
                                        className={classnames("w-4", {
                                          "transform rotate-90": open,
                                          [pathname.includes(menu) ? "color-blue" : "text-gray-900"]: true,
                                        })}
                                      />
                                    </Disclosure.Button>
                                    <Disclosure.Panel>
                                      {products.map((item) =>
                                        link ? (
                                          <ScrollLink
                                            containerId="gatsby-focus-wrapper"
                                            activeClass="bg-gray-50"
                                            className={classnames("p-3 flex items-center rounded-md hover:bg-gray-50")}
                                            key={item}
                                            to={item}
                                            spy={true}
                                            smooth={true}
                                            duration={500}
                                          >
                                            {formatMessage({
                                              id: `menu.products.${item}`,
                                            })}
                                          </ScrollLink>
                                        ) : (
                                          <a
                                            key={item}
                                            // to={`/${menu}/${item}`}
                                            className={classnames(
                                              "p-3 flex items-center rounded-md hover:bg-gray-50 cursor-pointer",
                                              {
                                                "bg-gray-50": pathname.includes(item),
                                              }
                                            )}
                                            onClick={() => {
                                              navigate(`/?anchor=${item}`);
                                            }}
                                          >
                                            <span
                                              className={classnames("ml-3 text-sm font-normal", {
                                                [pathname.includes(item) ? "color-blue" : "text-gray-900"]: true,
                                              })}
                                            >
                                              {formatMessage({
                                                id: `menu.products.${item}`,
                                              })}
                                            </span>
                                          </a>
                                        )
                                      )}
                                    </Disclosure.Panel>
                                  </Fragment>
                                )}
                              </Disclosure>
                            );
                          }
                          return link ? (
                            <ScrollLink
                              containerId="gatsby-focus-wrapper"
                              activeClass="bg-gray-50"
                              className={classnames("-m-3 p-3 flex items-center rounded-md hover:bg-gray-50")}
                              key={menu}
                              to={menu}
                              spy={true}
                              smooth={true}
                              duration={500}
                            >
                              {formatMessage({
                                id: `menu.${menu}`,
                              })}
                            </ScrollLink>
                          ) : (
                            <a
                              key={menu}
                              // to={`/${menu}`}
                              className={classnames(
                                "-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 cursor-pointer",
                                {
                                  "bg-gray-50": pathname.includes(menu),
                                }
                              )}
                              onClick={() => {
                                navigate(`/?anchor=${menu}`);
                              }}
                            >
                              <span
                                className={classnames("ml-3 text-sm font-normal", {
                                  [pathname.includes(menu) ? "color-blue" : "text-gray-900"]: true,
                                })}
                              >
                                {formatMessage({
                                  id: `menu.${menu}`,
                                })}
                              </span>
                            </a>
                          );
                        })}
                      </nav>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Fragment>
        )}
      </Popover>
    </header>
  );
};

export default Header;

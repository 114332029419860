import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper/core";
import "swiper/swiper.min.css";

import { useStaticQuery, graphql } from "gatsby";

SwiperCore.use([Autoplay]);

const LogoFooter = () => {
  const data = useStaticQuery(graphql`
    query {
      files: allFile(filter: { relativeDirectory: { eq: "logos" } }) {
        nodes {
          id
          publicURL
        }
      }
    }
  `);

  const { files = {} } = data;
  const { nodes = [] } = files;

  return (
    <div className="w-full h-48 bg-gray-f9 flex items-center justify-center">
      <div className="max-w-7xl">
        <Swiper
          slidesPerView="auto"
          spaceBetween={21}
          loop
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
        >
          {nodes.map((node) => (
            <SwiperSlide
              key={node.id}
              style={{
                width: "auto",
              }}
            >
              <img className="w-auto h-16" src={node.publicURL} alt="LOGO" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default LogoFooter;

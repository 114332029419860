import React from "react";
import Notification from "rc-notification";
import "rc-notification/assets/index.css";
import { ExclamationCircleIcon, CheckCircleIcon } from "@heroicons/react/solid";

const success = (msg) => {
  Notification.newInstance({}, (notification) => {
    notification.notice({
      content: (
        <span className="flex items-center text-gray-500">
          <CheckCircleIcon className="w-4 h-4 mr-2 text-green-400" />
          {msg}
        </span>
      ),
    });
  });
};

const error = (msg) => {
  Notification.newInstance({}, (notification) => {
    notification.notice({
      content: (
        <span className="flex items-center text-gray-500">
          <ExclamationCircleIcon className="w-4 h-4 mr-2 text-red-400" />
          {msg}
        </span>
      ),
    });
  });
};

const message = {
  success,
  error,
};

export default message;

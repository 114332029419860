import React, { Fragment, useState, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { createForm } from "rc-form";
import classnames from "classnames";
import { XCircleIcon } from "@heroicons/react/outline";
import { useIntl } from "gatsby-plugin-intl";
import axios from "axios";
import message from "../message";

const getError = (data) => {
  let error;
  if (data) {
    if (data instanceof Array) {
      error = data[0];
    } else if (data instanceof Object) {
      error = Object.values(data);
    } else if (typeof data === "string") {
      error = data;
    }
    if (typeof error === "string") {
      return error;
    } else if (error) {
      return getError(error);
    }
  }

  return error;
};

const FreeTrialForm = createForm()((props) => {
  const { form, type: typeInProps, onSuccess } = props;
  const { getFieldDecorator, getFieldError, validateFields } = form;
  const { formatMessage } = useIntl();

  const timer = useRef(null);

  const [type, setType] = React.useState("1");

  React.useEffect(() => {
    if (typeInProps) {
      setType(typeInProps);
    }
  }, [typeInProps]);

  const [active, setActive] = React.useState(false);
  const [second, setSecond] = React.useState(0);
  const [submitting, setSubmitting] = React.useState(false);

  React.useEffect(() => {
    if (active && second > 0) {
      timer.current = setInterval(() => {
        setSecond((s) => {
          if (s === 1) {
            setActive(false);
            clearInterval(timer.current);
          }
          return s - 1;
        });
      }, 1000);
    } else if (active && second <= 0) {
      setActive(!active);
    }
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [active, second]);

  const handleClock = () => {
    setSecond(60);
    setActive(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (submitting) {
      return;
    }
    validateFields((err, values) => {
      if (!err) {
        setSubmitting(true);
        const payload = {
          ...values,
          web_type: Number(type),
        };

        axios
          .post("/web/create/count/", payload)
          .then((response) => {
            setSubmitting(false);
            console.log(`response`, response);
            if (response.status === 200) {
              message.success(formatMessage({ id: "message.success.submit" }));
              onSuccess && onSuccess();
              return;
            }

            const errorStr = getError(response?.data);
            message.error(errorStr || formatMessage({ id: "message.failed.submit" }));
          })
          .catch((error) => {
            setSubmitting(false);
            console.error(error);
            const errorStr = getError(error?.response?.data);
            message.error(errorStr || formatMessage({ id: "message.failed.submit" }));
          });
      }
    });
  };

  const fetchCode = (values) => {
    setActive(true);
    axios
      .post("/web/get/code/", {
        name: values.name,
        phone: values.phone,
      })
      .then((response) => {
        if (response.status === 200) {
          handleClock();
          return;
        }
        const errorStr = getError(response?.data);
        message.error(errorStr || formatMessage({ id: "message.failed.send" }));
      })
      .catch((error) => {
        console.error(error);
        const errorStr = getError(error?.response?.data);
        message.error(errorStr || formatMessage({ id: "message.failed.send" }));
      });
  };

  const getCode = () => {
    if (active) {
      return;
    }
    validateFields(["name", "phone"], (err, values) => {
      if (!err) {
        fetchCode(values);
      }
    });
  };

  return (
    <form className="mt-8" onSubmit={handleSubmit}>
      {false && (
        <div className="mt-1 relative rounded flex flex-wrap">
          <div className="flex items-center mr-6 mb-4">
            <input
              id="free-type1"
              type="radio"
              name="radio"
              className="hidden"
              value="1"
              checked={type === "1"}
              onChange={() => {
                setType("1");
              }}
            />
            <label htmlFor="free-type1" className="flex items-center cursor-pointer">
              <span className="w-4 h-4 inline-block mr-2 rounded-full border border-grey"></span>
              {formatMessage({ id: "menu.products.efms" })}
            </label>
          </div>
          <div className="flex items-center mr-6 mb-4">
            <input
              id="free-type2"
              type="radio"
              name="radio"
              className="hidden"
              value="1"
              checked={type === "2"}
              onChange={() => {
                setType("2");
              }}
            />
            <label htmlFor="free-type2" className="flex items-center cursor-pointer">
              <span className="w-4 h-4 inline-block mr-2 rounded-full border border-grey"></span>
              {formatMessage({ id: "menu.products.ecms" })}
            </label>
          </div>
        </div>
      )}

      <div className="mt-1 relative rounded">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center justify-center pointer-events-none">
          <span className="text-red-400">*</span>
        </div>
        {getFieldDecorator("name", {
          rules: [
            {
              required: true,
              message: formatMessage({ id: "message.required.name" }),
            },
          ],
        })(
          <input
            type="text"
            className={classnames("block w-full pl-7 pr-12 sm:text-sm border rounded focus:outline-none ", {
              [getFieldError("name")
                ? "border-red-300 focus:ring-red-400 focus:border-red-400"
                : "border-gray-300 focus:ring-blue-400 focus:border-blue-400"]: true,
            })}
            placeholder={formatMessage({ id: "global.full_name" })}
          />
        )}
      </div>
      {getFieldError("name") && (
        <span className="flex items-center tracking-wide text-red-500 text-xs mt-1 ml-1">{getFieldError("name")}</span>
      )}
      <div className="mt-3 relative rounded">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center justify-center pointer-events-none">
          <span className="text-red-400">*</span>
        </div>
        {getFieldDecorator("phone", {
          rules: [
            {
              required: true,
              message: formatMessage({ id: "message.required.phone" }),
            },
            {
              pattern: /^(?:(?:\+|00)86)?1\d{10}$/,
              message: formatMessage({ id: "message.required.phone_format" }),
            },
          ],
        })(
          <input
            type="text"
            className={classnames("block w-full pl-7 pr-12 sm:text-sm border rounded focus:outline-none ", {
              [getFieldError("phone")
                ? "border-red-300 focus:ring-red-400 focus:border-red-400"
                : "border-gray-300 focus:ring-blue-400 focus:border-blue-400"]: true,
            })}
            placeholder={formatMessage({ id: "global.phone" })}
          />
        )}
      </div>
      {getFieldError("phone") && (
        <span className="flex items-center tracking-wide text-red-500 text-xs mt-1 ml-1">{getFieldError("phone")}</span>
      )}
      <div className="mt-3 relative rounded">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center justify-center pointer-events-none">
          <span className="text-red-400">*</span>
        </div>
        <div className="flex">
          {getFieldDecorator("code", {
            rules: [
              {
                required: true,
                message: formatMessage({ id: "message.required.vali_code" }),
              },
            ],
          })(
            <input
              type="text"
              className={classnames("flex-1 block w-full pl-7 pr-12 sm:text-sm border rounded focus:outline-none ", {
                [getFieldError("code")
                  ? "border-red-300 focus:ring-red-400 focus:border-red-400"
                  : "border-gray-300 focus:ring-blue-400 focus:border-blue-400"]: true,
              })}
              placeholder={formatMessage({ id: "global.code.validate" })}
            />
          )}
          <button
            type="button"
            className="ml-2 bg-blue text-white rounded px-3"
            style={{
              minWidth: 110,
            }}
            disabled={active}
            onClick={getCode}
          >
            {active
              ? `${second}${formatMessage({ id: "unit.seconds" })}`
              : formatMessage({ id: "global.code.get.validate" })}
          </button>
        </div>
      </div>
      {getFieldError("code") && (
        <span className=" flex items-center tracking-wide text-red-500 text-xs mt-1 ml-1">{getFieldError("code")}</span>
      )}
      <div className="mt-3 relative rounded">
        {getFieldDecorator("company", {
          rules: [],
        })(
          <input
            type="text"
            className="block w-full pl-7 pr-12 sm:text-sm border rounded focus:outline-none border-gray-300 focus:ring-blue-400 focus:border-blue-400"
            placeholder={formatMessage({ id: "placeholder.company.your" })}
          />
        )}
      </div>
      <div className="mt-3 relative rounded">
        {getFieldDecorator("email", {
          rules: [
            {
              required: false,
            },
            {
              pattern:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: formatMessage({ id: "message.required.email_format" }),
            },
          ],
        })(
          <input
            type="text"
            className={classnames("flex-1 block w-full pl-7 pr-12 sm:text-sm border rounded focus:outline-none ", {
              [getFieldError("email")
                ? "border-red-300 focus:ring-red-400 focus:border-red-400"
                : "border-gray-300 focus:ring-blue-400 focus:border-blue-400"]: true,
            })}
            placeholder={formatMessage({ id: "placeholder.email.your" })}
          />
        )}
      </div>
      {getFieldError("email") && (
        <span className="flex items-center tracking-wide text-red-500 text-xs mt-1 ml-1">{getFieldError("email")}</span>
      )}
      <div className="mt-4">
        <button
          type="submit"
          className="w-full inline-flex items-center justify-center px-4 py-2 text-base font-medium text-white bg-blue border border-transparent rounded hover:bg-blue-500 focus:outline-none"
        >
          <svg
            className={classnames("animate-spin -ml-5 mr-3 h-5 w-5 text-white ease-in-out duration-500 delay-300", {
              [submitting ? "opacity-100" : "opacity-0"]: true,
            })}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          {formatMessage({ id: "global.trial.get_account" })}
        </button>
      </div>
    </form>
  );
});

const FreeTrial = (props) => {
  const { className, type, children, ...rest } = props;
  const { formatMessage } = useIntl();

  const [visible, setVisible] = useState(false);

  const handleOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(`open`, 1);
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <Fragment>
      <button className={classnames(className, "swiper-no-swiping")} {...rest} onClick={handleOpen}>
        {children ??
          formatMessage({
            id: "global.trial.free",
          })}
      </button>
      <Transition appear show={visible} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={handleClose}>
          <div className="min-h-screen text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay
                className="fixed inset-0"
                style={{
                  background: "rgba(0, 0, 0, 0.5)",
                }}
              />
            </Transition.Child>

            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="relative inline-block w-full max-w-lg py-6 px-8 sm:py-14 sm:px-16 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl">
                <XCircleIcon
                  className="absolute top-4 right-4 w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                  onClick={handleClose}
                />
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  {formatMessage({ id: "message.trial.title" })}
                </Dialog.Title>

                <FreeTrialForm
                  type={type}
                  onSuccess={() => {
                    setVisible(false);
                  }}
                />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </Fragment>
  );
};

export default FreeTrial;

import React, { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { FreeTrial } from "../";
import { PhoneOutgoingIcon, QrcodeIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { useIntl } from "gatsby-plugin-intl";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import { animateScroll } from "react-scroll";
import { cx } from "@emotion/css";

const BackTop = (props) => {
  const { type, link, scrollShow = true } = props;
  const queryData = useStaticQuery(graphql`
    query {
      qrcode: allFile(filter: { relativeDirectory: { eq: "qrcode/efms" } }) {
        nodes {
          id
          publicURL
          name
        }
      }
      qrcode2: allFile(filter: { relativeDirectory: { eq: "qrcode/ecms" } }) {
        nodes {
          id
          publicURL
          name
        }
      }
      qrcode3: allFile(filter: { relativeDirectory: { eq: "qrcode/mjk" } }) {
        nodes {
          id
          publicURL
          name
        }
      }
      qrcode4: allFile(filter: { relativeDirectory: { eq: "qrcode/mjk2" } }) {
        nodes {
          id
          publicURL
          name
        }
      }
    }
  `);
  const { qrcode = {}, qrcode2 = {}, qrcode3 = {}, qrcode4 = {} } = queryData;
  const { nodes = [] } = qrcode;
  const { nodes: nodes2 = [] } = qrcode2;
  const { nodes: nodes3 = [] } = qrcode3;
  const { nodes: nodes4 = [] } = qrcode4;

  const ecmsWXCodeURL = useMemo(() => {
    const data = nodes2.find((i) => i.name === "ecms_wx");
    return data ? data.publicURL : null;
  }, [nodes2]);

  const efmsWXCodeURL = useMemo(() => {
    const data = nodes.find((i) => i.name === "efms_wx");
    return data ? data.publicURL : null;
  }, [nodes]);

  const efmsAPPCodeURL = useMemo(() => {
    const data = nodes.find((i) => i.name === "efms_app");
    return data ? data.publicURL : null;
  }, [nodes]);

  const mjkWXCodeURL = useMemo(() => {
    const data = nodes3.find((i) => i.name === "mjk_qr");
    return data ? data.publicURL : null;
  }, [nodes3]);

  const mjk2WXCodeURL = useMemo(() => {
    const data = nodes4.find((i) => i.name === "mjk2_qr");
    return data ? data.publicURL : null;
  }, [nodes4]);

  const { formatMessage } = useIntl();
  const scrollToTop = () => {
    link
      ? animateScroll.scrollToTop({ containerId: "gatsby-focus-wrapper" })
      : document.getElementById("gatsby-focus-wrapper").scrollTo(0, 0);
  };

  const is_mobile = () => {
    var regex_match =
      /(nokia|iphone|android|motorola|^mot-|softbank|foma|docomo|kddi|up.browser|up.link|htc|dopod|blazer|netfront|helio|hosin|huawei|novarra|CoolPad|webos|techfaith|palmsource|blackberry|alcatel|amoi|ktouch|nexian|samsung|^sam-|s[cg]h|^lge|ericsson|philips|sagem|wellcom|bunjalloo|maui|symbian|smartphone|midp|wap|phone|windows ce|iemobile|^spice|^bird|^zte-|longcos|pantech|gionee|^sie-|portalmmm|jigs browser|hiptop|^benq|haier|^lct|operas*mobi|opera*mini|320x320|240x320|176x220)/i;
    var u = typeof window !== "undefined" ? window.navigator.userAgent : null;
    if (null == u) {
      return true;
    }
    var result = regex_match.exec(u);

    if (null == result) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div
      className={cx(
        "fixed bottom-14 w-14 h-auto flex flex-col z-50 transform transition -right-14",
        scrollShow && "-translate-x-20 xl:-translate-x-28"
      )}
    >
      {!is_mobile() ? (
        <Tooltip placement="left" overlay={<div className="bg-blue text-white p-2 text-base">+86（512）62920285</div>}>
          <a
            className="flex items-center justify-center w-14 h-14 bg-blue cursor-pointer hover:bg-blue-500 text-white transition-all"
            style={{
              marginTop: 2,
            }}
          >
            <PhoneOutgoingIcon className="w-8 h-8" />
          </a>
        </Tooltip>
      ) : (
        <a
          className="flex items-center justify-center w-14 h-14 bg-blue cursor-pointer hover:bg-blue-500 text-white transition-all"
          style={{
            marginTop: 2,
          }}
          href="tel:+86（512）62920285"
        >
          <PhoneOutgoingIcon className="w-8 h-8" />
        </a>
      )}

      <Tooltip
        placement="left"
        overlay={
          <div className="inline-grid grid-cols-2 justify-center bg-blue text-white">
            <div className="cursor-pointer transition-all hover:bg-blue-500 p-2">
              <img className="mx-auto mb-2 w-18 h-18" src={mjkWXCodeURL} alt={"mjk wx"} />
              <div className="text-sm pb-1 text-center">
                <div>{formatMessage({ id: "global.mjk_short" })}</div>
                <div>{formatMessage({ id: "global.wx_public" })}</div>
              </div>
            </div>
            <div className="cursor-pointer transition-all hover:bg-blue-500 p-2">
              <img className="mx-auto mb-2 w-18 h-18" src={efmsWXCodeURL} alt={"efms wx"} />
              <div className="text-sm pb-1 text-center">
                <div>{formatMessage({ id: "global.efms" })}</div>
                <div>{formatMessage({ id: "global.service_no" })}</div>
              </div>
            </div>
            <div className="cursor-pointer transition-all hover:bg-blue-500 p-2">
              <img className="mx-auto mb-2 w-18 h-18" src={efmsAPPCodeURL} alt={"efms app"} />
              <div className="text-sm pb-1 text-center">
                <div>{"eFMS"}</div>
                <div>{formatMessage({ id: "global.app_down" })}</div>
              </div>
            </div>
            <div className="cursor-pointer transition-all hover:bg-blue-500 p-2">
              <img className="mx-auto mb-2 w-18 h-18" src={mjk2WXCodeURL} alt={"mjk h5"} />
              <div className="text-sm pb-1 text-center">
                <div>{formatMessage({ id: "global.efms_data" })}</div>
                <div>{formatMessage({ id: "global.scan_code" })}</div>
              </div>
            </div>
          </div>
        }
        arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
      >
        <div
          className="relative flex items-center justify-center w-14 h-14 bg-blue cursor-pointer hover:bg-blue-500 text-white transition-all"
          style={{
            marginTop: 2,
          }}
        >
          <QrcodeIcon className="w-8 h-8" />
        </div>
      </Tooltip>
      {/* <Tooltip
        placement="left"
        overlay={
          <div className="bg-blue text-white p-2 select-none">
            <div className="font-bold text-base">客服在线时间</div>
            <div className="text-sm">周一至周五 (法定节假日除外)</div>
            <div className="text-sm">9:00~18:00</div>
          </div>
        }
      > */}
      <button
        className="flex flex-col items-center justify-center w-14 h-14 bg-blue cursor-pointer hover:bg-blue-500 text-sm leading-4 text-white transition-all swiper-no-swiping select-none"
        style={{
          marginTop: 2,
        }}
        onClick={() => {
          const kfEl = document.querySelector(".nb-icon-bridge0");
          const mbKfEl = document.querySelector(".nb-icon-wrap-base");
          // 客服是否展开
          const nbwlToggle = document.querySelector("#nbwlToggle");
          kfEl && kfEl.click();
          mbKfEl && mbKfEl.click();
          nbwlToggle && nbwlToggle.click();
        }}
      >
        {formatMessage({ id: "global.service" })
          .split("/")
          .map((v, i) => (
            <span key={i}>{v}</span>
          ))}
      </button>
      {/* </Tooltip> */}
      <FreeTrial
        className="flex flex-col items-center justify-center w-14 h-14 bg-blue cursor-pointer hover:bg-blue-500 text-sm leading-4 text-white transition-all"
        style={{
          marginTop: 2,
        }}
        type={type}
      >
        {formatMessage({
          id: "global.trial.apply",
        })
          .split("/")
          .map((v, i) => (
            <span key={i}>{v}</span>
          ))}
      </FreeTrial>
      <div
        className="flex items-center justify-center w-14 h-8 bg-blue cursor-pointer hover:bg-blue-500 text-white"
        style={{
          marginTop: 2,
        }}
        onClick={scrollToTop}
        aria-hidden="true"
      >
        <ChevronUpIcon className="w-6 h-6" />
      </div>
    </div>
  );
};

export default BackTop;

/*
 * @Author: zhang yusen.zhang@mjk24.com
 * @Date: 2022-04-21 09:15:26
 * @LastEditors: zhang yusen.zhang@mjk24.com
 * @LastEditTime: 2022-07-29 16:24:16
 */
import React from "react";
import image from "@/static/apply-footer.png";
import tel from "@/static/icons/tel.png";
import { FreeTrial } from "../";
import { useIntl } from "gatsby-plugin-intl";

const ApplyFooter = ({ type }) => {
  const { formatMessage } = useIntl();
  return (
    <div
      className="w-full bg-gray-f9 h-56 flex flex-col justify-center items-center bg-no-repeat bg-center bg-cover bg-full text-white"
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <h3 className="text-2xl md:text-3xl">{formatMessage({ id: "message.products.contact" })}</h3>
      <div className="text-base mt-5 flex items-center">
        <img className="w-7 h-auto" src={tel} alt="Tel." />
        <a href="tel:+86（512）62920285" className="ml-3">
          +86（512）62920285
        </a>
      </div>
      {/* <FreeTrial type={type} className="mt-5 px-8 py-2 text-white font-normal text-sm bg-orange hover:bg-yellow-500" /> */}
    </div>
  );
};

export default ApplyFooter;
